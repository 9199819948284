.team-member {
  text-align: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.image-holder {
  margin-bottom: 10px;
  position: relative;
}

.image-holder img {
  width: 100%;
  transition: opacity 0.4s;
}

.default-img {
  opacity: 1;
}

.selected-img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.team-member:hover {
  .default-img {
    opacity: 0;
  }
  .selected-img {
    opacity: 1;
  }
}

.info {
  width: 100%;
  color: black;
}
