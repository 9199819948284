.digital-assets-container {
  width: 100%;
  padding: 50px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.digital-assets-content {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 50px;
  max-width: 1200px;
  width: 100%;
}

/**************************************** text ****************************************/

.digital-assets-text {
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.digital-assets-text h1 {
  width: 100%;
  animation-fill-mode: both;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
  animation-name: text-slide;
  margin-bottom: 20px;
}

.digital-assets-text p {
  animation-fill-mode: both;
  animation-duration: 1000ms;
  animation-delay: 10ms;
  animation-iteration-count: 1;
  opacity: 1;
  animation-name: text-slide;
  margin-bottom: 10px;
}
.digital-assets-text a {
  animation-fill-mode: both;
  animation-duration: 1000ms;
  animation-delay: 20ms;
  animation-iteration-count: 1;
  opacity: 1;
  animation-name: text-slide;
  text-decoration: none;
}

.digital-assets-text a:visited {
  color: #0000ee;
}

@keyframes text-slide {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0px, 0px);
    -webkit-transform: translate3d(100%, 0px, 0px);
  }

  100% {
    opacity: 1;
    transform: none;
    -webkit-transform: none;
  }
}

/**************************************** animation/video ****************************************/

.digital-assets-animation {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}

.video {
  width: 120%;
  background: transparent;
  outline: none;
  clip-path: inset(1px 1px 1px 1px);
  animation-fill-mode: both;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
  animation-name: video-slide;
  mask-image: linear-gradient(
    to right,
    transparent,
    black 20%,
    black 80%,
    transparent
  );
  mask-mode: alpha;
}

@keyframes video-slide {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0px, 0px);
    -webkit-transform: translate3d(-100%, 0px, 0px);
  }

  100% {
    opacity: 1;
    transform: none;
    -webkit-transform: none;
  }
}

@media (max-width: 1024px) {
  .digital-assets-container {
    padding: 100px 0 50px 0;
  }
  .digital-assets-content {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  .digital-assets-text {
    width: 100%;
    justify-content: center;
    text-align: center;
  }

  .video {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .digital-assets-content {
    padding: 0 20px;
  }
}
