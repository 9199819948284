.footer-container {
  width: 100%;
  padding: 30px 0 30px 0;
  background: linear-gradient(to right, #252a35, #343842, #252a35);
  position: relative;
}

.footer-container::before {
  content: "";
  background: linear-gradient(90deg, #922580, #a02a76, #ee453d);
  height: 5px;
  position: absolute;
  top: 0;
  width: 100%;
}

.footer-content {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 50px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-logo {
  flex: 1 1;
}

.footer-section {
  align-self: flex-start;
  color: white;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.footer-section-content {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.footer-title {
  position: relative;
  padding-left: 10px;
  margin-bottom: 50px;
}

.footer-title h3::before {
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(to bottom, #922580, #a02a76, #ee453d);
}

.footer-container a {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 14px;
  margin-bottom: 3px;
  color: white;
}

.footer-container a:hover {
  background: -webkit-linear-gradient(#922580, #a02a76, #ee453d);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* CONNECT */

.footer-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-icon {
  display: flex;
  column-gap: 10px;
}

.footer-icon img {
  width: 15px;
  height: auto;
}

/* CONTACT */

.footer-form {
  display: flex;
  flex-direction: column;
}

.textarea-holder {
  height: 70px;
}
.textarea {
  background-color: #343842;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  color: #fff;
  font-size: 12px;
  height: 66px;
  outline: none;
  padding: 5px 10px;
  resize: none;
  width: 100%;
}

.input-holder {
  background: linear-gradient(to right, #922580, #a02a76, #ee453d);
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 2px;
}

input {
  background-color: #343842;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  color: #fff;
  font-size: 12px;
  height: 26px;
  outline: none;
  padding: 2px 10px;
  width: 100%;
}
input:not([type="range"]):not([type="color"]):not([type="button"]):not(
    [type="reset"]
  ):not([type="submit"]):not([type="file"]) {
  writing-mode: horizontal-tb !important;
}

/* FORM BUTTON */

.form-button button {
  background: linear-gradient(to right, #922580, #a02a76, #ee453d);
  border: none;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 12px;
  margin-left: auto;
  padding: 4px 25px;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-section {
    margin-bottom: 30px;
  }
  .footer-logo {
    order: 4;
  }
  .footer-title {
    margin-bottom: 20px;
  }

  .footer-contact {
    width: 100%;
  }
  .footer-form {
    width: 100%;
    max-width: 320px;
  }
}

.Toastify__toast-container {
  width: 50vw !important;
}
