.article {
  background-color: rgb(249, 243, 250);
  margin: 20px 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 400px;
  width: 300px;
  gap: 12px;
  border-radius: 12px;
  flex: 1;
}

.article-img,
.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
}

.article-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transform: scale(1);
}

.article-img img {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: transform 0.3s ease;
  object-fit: cover;
}

.article-img:hover img {
  cursor: pointer;
  transform: scale(1.1);
}

.article-newsletter {
  background-color: rgb(240, 235, 241);
  width: fit-content;
  padding: 2px 4px;
  font-size: 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.article-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.article-date {
  font-size: 12px;
  color: rgb(118, 117, 118);
}

.article-title {
  font-size: 22px;
  font-weight: 600;
  display: flex;
  gap: 5px;
  position: relative;
  align-items: center;
  text-decoration: none;
  color: black;
}

.article-title:hover {
  text-decoration: underline;
}

:global(.slick-prev:before),
:global(.slick-next:before) {
  color: rgb(118, 117, 118);
}
:global(.slick-slide) {
  display: flex !important;
  justify-content: center;
}
