.team-section-container {
  width: 100%;
  background-color: #f8f3f9;
  padding: 50px 50px;
  position: relative;
}

.team-section-content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.team-section-text {
  text-transform: uppercase;
  font-weight: 600;
}

.team-section-members {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 50px;
}

@media (max-width: 1204px) {
  .team-section-text {
    margin-top: 50px;
  }
}

@media (max-width: 1024px) {
  .team-section-container {
    padding: 50px 0;
  }
  .team-section-members {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .team-section-container {
    padding: 50px 0;
  }
  .team-section-content {
    width: 100%;
    padding: 0 20px;
  }
  .team-section-members {
    width: 100%;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
  }
}
