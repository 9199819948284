.wrapper {
  padding: 20px 0;
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  font-family: "Poppins", sans-serif;
}

.last-updated {
  width: 100%;
  text-align: start;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.block p {
  font-size: 16px;
  font-weight: 100;
  line-height: 20px;
}

.block-title {
  font-size: 35px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 10px;
}

.block-subtitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-right: 5px;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
}

@media (max-width: 990px) {
  .block-title {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .wrapper {
    width: 100%;
    max-width: 100%;
    padding: 1rem;
  }
}
