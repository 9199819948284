.heroes-section {
  background-image: url(../../assets/heroes-section-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 500px;
  padding: 50px 0;
  position: relative;
}

@media (max-width: 1200px) {
  .heroes-section {
    min-height: 780px;
  }
}
@media (max-width: 768px) {
  .heroes-section {
    min-height: 500px;
  }
}
@media (max-width: 576px) {
  .heroes-section {
    min-height: 510px;
  }
}

.heroes-container {
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 50px;
  width: 100%;
}

.heroes-content {
  display: flex;
  align-items: center;
  width: 100%;
}

@media (max-width: 1204px) {
  .heroes-container {
    padding: 0 20px;
  }
  .heroes-content {
    flex-direction: column;
    text-align: center;
  }
}

.heroes-text {
  flex: 1 1;
  color: #fff;
  z-index: 5;
}

.heroes-text h1 {
  font-size: 56px;
  font-weight: 600;
  line-height: 50px;
  margin-bottom: 10px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

@media (max-width: 1204px) {
  .heroes-text h1 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }
}

.heroes-text p {
  margin-bottom: 30px;
}

.heroes-button {
  background-color: transparent;
  border: 1.5px solid white;
  border-radius: 20px;
  color: white;
  font-size: 14px;
  padding: 10px 60px;
  text-decoration: none;
  width: fit-content;
  transition: all 0.5s ease;
  margin-top: 10px;
  transition: all 0.3s, color 0.3s;
}
.heroes-button:hover {
  background-color: #fff;
  color: rgb(8, 135, 203);
}

.heroes-section-text p {
  margin-bottom: 30px;
}

.heroes-parallax {
  flex: 1 1;
  height: 100%;
  position: relative;
}

.heroes-parallax img {
  overflow-clip-margin: content-box;
  overflow: clip;
}

.layer {
  max-width: 100%;
  position: absolute;
}

.layer-one {
  bottom: -20px;
  left: 90px;
  max-width: 80%;
  min-width: 388px;
  z-index: 4;
}

@media (max-width: 1204px) {
  .layer-one {
    left: -195px;
  }
}

@media (max-width: 768px) {
  .layer-one {
    left: -154px;
    min-width: 290px;
    bottom: -80px;
  }
}

.layer-two {
  bottom: -120px;
  max-width: 120%;
  min-width: 585px;
  z-index: 3;
}

@media (max-width: 1204px) {
  .layer-two {
    left: -290px;
  }
}
@media (max-width: 768px) {
  .layer-two {
    left: -178px;
    min-width: 350px;
  }
}

.layer-three {
  bottom: 110px;
  left: 130px;
  max-width: 455px;
  z-index: 2;
}

@media (max-width: 1204px) {
  .layer-three {
    left: -227px;
  }
}
@media (max-width: 768px) {
  .layer-three {
    left: -104px;
    max-width: 200px;
    bottom: 20px;
  }
}

.layer-four {
  bottom: 120px;
  left: -350px;
  max-width: 250%;
  z-index: 1;
}

@media (max-width: 1204px) {
  .layer-four {
    max-width: unset;
    left: -500px;
    width: 1000px;
  }
}
@media (max-width: 768px) {
  .layer-four {
    left: -380px;
    width: 750px;
    bottom: 0px;
  }
}
