.quality-content {
  width: 100%;
  overflow: hidden;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.quality-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 50px;
  width: 100%;
}

.quality-content-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quality-content-logo {
  left: 0;
  max-width: 40%;
  position: absolute;
  top: -60px;
  z-index: 0;
}

.quality-content-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  width: 100%;
}

.quality-content-text h1 {
  margin-bottom: 20px;
}

.quality-content-text a:visited {
  color: #0000ee;
}

.quality-content-text p {
  margin-bottom: 10px;
}

.quality-content-animation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  background: transparent;
  border: none;
  outline: none;
}

.quality-content-video {
  width: 100%;
  outline: none;
  clip-path: inset(1px 1px 1px 1px);
}

@media (max-width: 1024px) {
  .quality-content-logo {
    display: none;
  }
  .quality-content-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .quality-content-text {
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .quality-content-container {
    padding: 0 20px;
  }
}
