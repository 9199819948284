.hero {
  background: url(../../assets/hero-bg.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 92vh; */
  height: 100vh;
  max-height: 1200px;
  min-height: 700px;
  width: 100vw;
  position: relative;
  overflow-y: hidden;
}

/* @media (max-width: 480px) {
  .hero {
    max-height: 85vh;
  }
} */

.hero-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.hero-content {
  width: 80%;
  display: flex;
  flex-direction: row;
  height: 100%;
  row-gap: 1rem;
}

.hero-text {
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  flex: 1;
}

.hero-text h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 2px;
  text-transform: uppercase;
  width: 100%;
  color: white;
}

.hero-text p {
  width: 100%;
  color: white;
  margin-bottom: 10px;
}

.hero-img {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  flex: 2;
}
.hero-img img {
  height: auto;
  width: 100%;
}

/********************************************  MEDIA QUERIES ********************************************/

@media (max-width: 575px) {
  .hero-content {
    row-gap: 0.5rem;
  }
  .hero-img {
    margin-top: 50px;
    display: block;
    height: auto;
    width: 120%;
    transform: translateX(-28px);
  }
}

@media (max-width: 991px) {
  .hero-content {
    flex-direction: column;
    text-align: center;
  }
  .hero-button {
    margin: 0 auto;
  }
  .hero-social-container {
    display: none;
  }
}

@media (max-width: 1250px) {
  .hero-text h1 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1250px) {
  .hero-text h1 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }
}

/********************************************  BUTTON ********************************************/

.hero-button {
  background-color: transparent;
  border: 1.5px solid white;
  border-radius: 20px;
  color: white;
  font-size: 14px;
  padding: 10px 60px;
  text-decoration: none;
  width: fit-content;
  transition: all 0.5s ease;
  margin-top: 10px;
}
.hero-button:hover {
  color: rgb(211, 15, 110);
  background-color: #fff;
}

/********************************************  BALLS ********************************************/

.ball {
  position: absolute;
}
@media (max-width: 991px) {
  .ball {
    display: none;
  }
}

.ballOne {
  left: 1%;
  top: 15%;
  width: 180px;
}

.ballTwo {
  left: 35%;
  top: 57%;
  width: 120px;
}

.ballThree {
  left: 52%;
  top: -10%;
  width: 260px;
}

.ballFour {
  bottom: 22%;
  right: 13%;
  width: 200px;
}

.ballFive {
  right: 4%;
  top: 13%;
  width: 180px;
}

/********************************************  ICONS ********************************************/

.hero-social-container {
  padding: 0;
  margin: 0;
  position: absolute;
  right: 2%;
  top: 15%;
  z-index: 1100;
}

.hero-icons {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  margin-top: 1rem;
}
