.footer-carousel-viewport {
  padding: 0.3rem 0;
  background-color: rgb(240, 235, 241);
  position: relative;
  width: 100%;
  overflow: hidden;
}

.footer-carousel {
  width: 100%;
  height: auto;
  object-fit: cover;
  mask-image: linear-gradient(
    to right,
    transparent,
    black 20%,
    black 80%,
    transparent
  );
  mask-mode: alpha;
}

.footer-carousel-container {
  display: flex;
  flex-direction: row;
  width: 200%;
  position: relative;
  margin-bottom: 1rem;
  height: 147px;
  animation: footer-slide 20s linear infinite;
  margin: 0;
  column-gap: 0;
  &:hover {
    animation-play-state: paused;
  }
}

.footer-carousel-track {
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: 100%;
  margin: 0;
}

.footer-carousel-track-two {
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: 100%;
  margin: 0;
}

.footer-carousel-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  animation: footer-scaling-back 0.5s;
  opacity: 0.6;
  transition: opacity 0.4s;
  &:hover {
    animation: footer-scaling 0.5s;
    animation-fill-mode: forwards;
    opacity: 1;
  }

  border-radius: 20%;
}

.footer-carousel-card > img {
  height: 50%;
  width: 50%;
}

@keyframes footer-slide {
  0% {
    left: 0%;
  }

  100% {
    left: -100%;
  }
}

@keyframes footer-scaling {
  0% {
    scale: 1;
  }

  100% {
    scale: 1.1;
  }
}

@keyframes footer-scaling-back {
  0% {
    scale: 1.1;
  }
  100% {
    scale: 1;
  }
}

@media (max-width: 1400px) {
  .footer-carousel-container {
    justify-content: space-around;
    width: 400%;
    animation: footer-slide-two 10s linear infinite;
  }
  .footer-carousel-track {
    justify-content: space-around;
    width: 200%;
  }
  .footer-carousel-track-two {
    justify-content: space-around;
    width: 200%;
  }
}

@media (max-width: 1400px) {
  @keyframes footer-slide-two {
    0% {
      left: 0%;
    }

    100% {
      left: -200%;
    }
  }
}

@media (max-width: 480px) {
  .footer-carousel-container {
    height: 90px;
  }
}
