.hero-carousel-viewport {
  width: 100%;
  overflow: hidden;
}

.hero-carousel {
  width: 100%;
  height: auto;
  object-fit: cover;
  mask-image: linear-gradient(
    to right,
    transparent,
    black 20%,
    black 80%,
    transparent
  );
  mask-mode: alpha;
}

.hero-carousel-container {
  display: flex;
  flex-direction: row;
  width: 500%;
  position: relative;
  margin-bottom: 1rem;
  height: 147px;
  animation: hero-slide 35s linear infinite;
  margin: 0;
  column-gap: 0;
  &:hover {
    animation-play-state: paused;
  }
}

.hero-carousel-track {
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 250vw;
  height: 100%;
  margin: 0;
}

.hero-carousel-track-two {
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 250vw;
  height: 100%;
  margin: 0;
}

.hero-carousel-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  animation: hero-scaling-back 0.5s;
  opacity: 0.6;
  transition: opacity 0.4s;
  &:hover {
    animation: hero-scaling 0.5s;
    animation-fill-mode: forwards;
    opacity: 1;
  }

  border-radius: 20%;
}

.hero-carousel-card > img {
  height: 80%;
  width: 80%;
}

@keyframes hero-slide {
  0% {
    left: 0%;
  }

  100% {
    left: -250%;
  }
}

@keyframes hero-scaling {
  0% {
    scale: 1;
  }

  100% {
    scale: 1.1;
  }
}

@keyframes hero-scaling-back {
  0% {
    scale: 1.1;
  }
  100% {
    scale: 1;
  }
}

@media (max-width: 1400px) {
  .hero-carousel-container {
    justify-content: space-around;
    width: 800%;
    animation: hero-slide-two 30s linear infinite;
  }
  .hero-carousel-track {
    justify-content: space-around;
    width: 400%;
  }
  .hero-carousel-track-two {
    justify-content: space-around;
    width: 400%;
  }
  .hero-carousel-card {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media (max-width: 1400px) {
  @keyframes hero-slide-two {
    0% {
      left: 0%;
    }

    100% {
      left: -400%;
    }
  }
}

@media (max-width: 480px) {
  .hero-carousel-container {
    height: 90px;
  }
}
