.side-drawer {
  background: url(../../../assets/hero-bg.jpeg) !important;
  position: absolute;
  width: 100% !important;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  z-index: 100;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
}

.side-drawer.open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.drawer-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: 100%;
}

.drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 100px; */
  margin-bottom: 20px;
}

.drawer-logo {
  width: 150px;
  height: auto;
}

.drawer-links ul {
  display: flex;
  flex-direction: column;
  gap: 30px;
  list-style: none;
  padding: 0;
}

.drawer-link {
  width: 100%;
}

.drawer-link a {
  text-decoration: none;
  color: white;
  width: 100%;
  justify-content: space-between;
}

.drawer-link a:visited {
  color: white;
}

.side-drawer p {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.side-drawer-links {
  text-transform: uppercase;
}

.side-drawer-links:hover,
.side-drawer:active {
  background-image: linear-gradient(to bottom, white, pink);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

@media (min-width: 991px) {
  .side-drawer {
    display: none;
  }
}

.side-drawer ul li:last-child {
  position: absolute;
  top: 70%;
  text-transform: uppercase;
}

.drawer-icons {
  display: flex !important;
  column-gap: 25px;
  justify-content: center;
  margin-top: 1rem;
}

@media (max-width: 575px) {
  .side-drawer {
    width: 100%;
  }
}
