.close-icon {
  width: 25px;
  height: 25px;
  position: relative;
  display: inline-flex;
  border-radius: 50%;
  color: #fff;
  align-items: center;
  justify-content: center;
}

/* .close-icon img {
  position: relative;
  padding: 5px;
} */

.close-icon:hover {
  outline: 2px solid #fff;
  border-radius: 2rem;
  outline-offset: 3px;
  transition-property: outline-offset;
  transition-duration: 0.1s;
}
