.toolbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.colored-toolbar {
  background: linear-gradient(
    rgb(165, 65, 127),
    rgb(184, 85, 129),
    rgb(209, 112, 131)
  );
}

.toolbar-navigation {
  width: 80%;
  display: flex;
  flex-direction: row;
  margin: 1rem;
  align-items: center;
}

.spacer {
  flex: 1;
}

.toolbar-logo {
  flex: 1;
}

.toolbar-navigation-items ul {
  list-style: none;
  margin: auto;
  display: flex;
}

.toolbar-navigation-items li {
  padding: 0 0.8rem;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.toolbar-navigation-items *:not(:last-child) {
  border-right: 2px solid white;
}

.toolbar-navigation-items a {
  color: white;
  text-decoration: none;
}

.toolbar-navigation-items a:hover,
.toolbar-navigation-items a:active {
  background-image: linear-gradient(to bottom, white, pink);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

@media (max-width: 1023px) {
  .toolbar-navigation-items {
    display: none;
  }
}

@media (max-width: 1200px) {
  .toolbar-navigation-items li {
    font-size: 0.8rem;
    padding: 0 0.5rem;
  }

  .toolbar-logo img {
    width: 150px;
    height: 33px;
  }
}

@media (min-width: 1024px) {
  .toolbar-burger-menu {
    display: none;
  }

  .toolbar-logo {
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .toolbar-navigation {
    width: 100%;
  }
}
