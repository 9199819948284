.community-section {
  padding: 50px 0;
  position: relative;
}

.community-section-container {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 0 50px;
}

.community-section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 750px;
  text-align: center;
}

.community-section-text {
  width: 100%;
  margin: 0 auto;
}

.community-section-text h1 {
  margin-bottom: 20px;
}

.community-section-animation {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}
.community-section-video {
  width: 100%;
  outline: none;
  clip-path: inset(1px 1px 1px 1px);
}

@media (max-width: 1024px) {
  .community-section-container {
    padding: 0 20px;
  }
}

@media (max-width: 480px) {
  .digital-assets-content {
    padding: 0 20px;
  }
}
