*,
:after,
:before {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body,
html {
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  scroll-behavior: smooth;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
}

h1 {
  font-size: 35px;
  font-weight: 600;
  line-height: 32px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

@media (max-width: 990px) {
  h1 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }
}

p {
  font-size: 16px;
  font-weight: 100;
  line-height: 20px;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 12px;
}
