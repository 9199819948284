.social-icon {
  display: inline-flex;
  width: 30px;
  height: 30px;
  text-decoration: none;
  font-size: 26px;
  color: #fff;
  border-radius: 50%;
  outline: 2px solid #fff;
  transition-property: outline-offset, outline-color, background-color;
  transition-duration: 0.25s;
  background-image: linear-gradient(
    to bottom,
    rgba(245, 246, 252, 0.52),
    rgba(117, 19, 93, 0.73)
  );
}

.social-icon:hover {
  outline-offset: 4px;
}

.twitter-icon {
  background-image: url(../../assets/social-media/twitter.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  outline-offset: 1px;
}

.telegram-icon {
  background-image: url(../../assets/social-media/telegram.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  outline-offset: 1px;
}

.linkedin-icon {
  background-image: url(../../assets/social-media/linkedin.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  outline-offset: 1px;
}
.discord-icon {
  background-image: url(../../assets/social-media/discord.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  outline-offset: 1px;
}
.youtube-icon {
  background-image: url(../../assets/social-media/youtube.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  outline-offset: 1px;
}

.twitter-icon:hover {
  background-image: url(../../assets/social-media/twitter.png);
  background-color: rgba(0, 0, 0, 0.742);
  outline-color: rgba(0, 0, 0, 0.742);
  background-repeat: no-repeat;
  background-position: center;
}
.linkedin-icon:hover {
  background-image: url(../../assets/social-media/linkedin.png);
  background-color: #0077b5;
  outline-color: #0077b5;
  background-repeat: no-repeat;
  background-position: center;
}

.telegram-icon:hover {
  background-image: url(../../assets/social-media/telegram.png);
  background-color: #229ed9;
  outline-color: #229ed9;
  background-repeat: no-repeat;
  background-position: center;
}

.discord-icon:hover {
  background-image: url(../../assets/social-media/discord.png);
  background-color: #5865f2;
  outline-color: #5865f2;
  background-repeat: no-repeat;
  background-position: center;
}

.youtube-icon:hover {
  background-image: url(../../assets/social-media/youtube.png);
  background-color: #ff0000;
  outline-color: #ff0000;
  background-repeat: no-repeat;
  background-position: center;
}
