.ih-video-section {
  width: 100%;
  height: max-content;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  gap: 24px;
}

.video-element {
  width: 100%;
  max-width: 1920px;
}

.text-wrapper {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 20%;
  transform: translateY(-20%);
  text-align: center;
  padding: 50px;
  width: 100%;
}

.text-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 50px;
}

.video-title {
  text-transform: capitalize;
  color: white;
}

.video-summary {
  color: white;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 100;
  margin-bottom: 10px;
}

.video-summary a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  margin: 0 5px;
  color: #1692ff;
}

.download-btn {
  display: inline-block;
  width: max-content;
  text-decoration: none;
  color: white;
  background: transparent;
  padding: 8px 26px;
  border-radius: 25px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  border: 1px solid white;
  position: relative;
  overflow: hidden;
}

.download-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: left 0.4s ease;
  z-index: 0;
}

.download-btn:hover::before {
  left: 0;
}

.download-btn span {
  position: relative;
  z-index: 1;
  color: white;
  transition: color 0.4s ease;
}

.download-btn:hover span {
  color: black;
}

@media (max-width: 480px) {
  .text-content {
    padding: 0 20px;
  }
}

@media (max-width: 768px) {
  .ih-video-section {
    height: 80vh;
  }

  .video-element {
    height: 80vh;
    width: auto;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .video-element {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .video-element {
    mask-image: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(0, 0, 0, 0.5) 50%
    );
    mask-image: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(0, 0, 0, 0.5) 50%
    );
  }

  .text-wrapper {
    padding: 0px;
  }
}

@media (min-width: 1025px) {
  .ih-video-section {
    padding: 0;
  }

  .video-element {
    mask-image: linear-gradient(
      to right,
      transparent,
      rgba(0, 0, 0, 0.1) 30%,
      rgba(0, 0, 0, 0.5) 70%,
      transparent
    );
    -webkit-mask-image: linear-gradient(
      to right,
      transparent,
      rgba(0, 0, 0, 0.1) 30%,
      rgba(0, 0, 0, 0.5) 70%,
      transparent
    );
  }

  .text-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    gap: 24px;
    align-items: flex-start;
    text-align: start;
  }

  .video-summary {
    width: 40%;
    font-size: 16px;
    line-height: 20px;
  }

  .video-summary a {
    font-size: 16px;
  }
}
