/* .investors-section-container {
  width: 100%;
  background-color: rgb(240, 235, 241);
  padding: 50px 50px;
  position: relative;
}

.investors-section-wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.investors-section-text {
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
}

.investors-section-content {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
}

.investors-section-content img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.investors-section-content img:hover {
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
}

.investors-section-content > a:nth-child(5) {
  grid-column: span 2;
  justify-self: center;
}

@media (max-width: 480px) {
  .investors-section-wrapper {
    padding: 0px 20px;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .investors-section-content {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1025px) {
  .investors-section-content {
    grid-template-columns: repeat(4, 1fr);
  }

  .investors-section-content > a:nth-child(5) {
    grid-column: span 4;
    justify-self: center;
  }
} */

.investors-section-container {
  width: 100%;
  background-color: rgb(240, 235, 241);
  padding: 50px 50px;
  position: relative;
}

.investors-section-wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.investors-section-text {
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
}

.investors-section-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 80px;
}

.investors-section-content img {
  width: 100%;
  transform: scale(0.9);
  height: auto;
  object-fit: contain;
}

.investors-section-content a:last-of-type img {
  transform: scale(0.6);
}

.investors-section-content img:hover {
  filter: drop-shadow(0.5px 1px 1px rgba(0, 0, 0, 0.2));
}

@media (max-width: 480px) {
  .investors-section-content a {
    width: 100%;
  }
  .investors-section-content img {
    max-height: 50px;
    height: 100%;
  }

  .investors-section-content {
    gap: 24px;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .investors-section-content img {
    max-height: 70px;
    height: 100%;
  }

  .investors-section-content {
    gap: 40px;
  }
}

@media (max-width: 1024px) {
  .investors-section-container {
    padding: 50px 0px;
  }
  .investors-section-wrapper {
    padding: 0px 20px;
  }
}
