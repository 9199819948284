.burger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

@media (min-width: 991px) {
  .burger-menu {
    display: none;
  }
}
