.banner-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  z-index: 100;
  background-color: white;
  padding: 10px;
  gap: 24px;
  height: 8vh;
  align-items: center;
}

.banner-text {
  font-size: 20px;
  font-weight: 800;
  color: rgb(121, 20, 86);
  display: flex;
  align-items: center;
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .banner-text {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .banner-text {
    text-align: center;
  }
  .banner-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .banner-container {
    gap: 4px;
  }
  .banner-text {
    font-size: 14px;
    font-weight: 700;
  }
}

@media (max-width: 400px) {
  .banner-container {
    gap: 2px;
  }
  .banner-text {
    font-size: 12px;
    font-weight: 700;
  }
}

@media (max-width: 300px) {
  .banner-container {
    gap: 4px;
  }
  .banner-text {
    font-size: 10px;
    font-weight: 700;
  }
}
